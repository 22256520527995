<template>
  <div class="forgetpassword-wrap">
    <div class="ms-forgetpassword">
      <div class="ms-title">Help you find the password back</div>
      <div class="steps">
        <el-steps
          :active="this.activeOfSteps"
          finish-status="success"
          align-center
        >
          <el-step title="Verify you"> </el-step>
          <el-step title="Verify code"> </el-step>
          <el-step title="Reset your password"> </el-step>
        </el-steps>
      </div>
      <div class="each-step" v-if="this.activeOfSteps === 1">
        <el-form
          ref="step1RulesForm"
          :model="query"
          :rules="step1Rules"
          label-width="0px"
          class="ms-content"
        >
          <el-form-item prop="emailOrUsername">
            <el-input
              v-model="query.emailOrUsername"
              :clearable="true"
              placeholder="Your student number or your email"
            >
            </el-input>
          </el-form-item>
          <div class="forgetpassword-btn">
            <el-button type="primary" @click="sendVerificationCode">
              Send verification code
            </el-button>
          </div>
        </el-form>
      </div>
      <div class="each-step" v-if="this.activeOfSteps === 2">
        <el-form
          ref="step2RulesForm"
          :model="query"
          :rules="step2Rules"
          label-width="0px"
          class="ms-content"
        >
          <div class="userInfo">
            <div>
              Below is your email receiving the verification code.
            </div>
            <div>Please copy the verification code and verfy it.</div>
            <div style="margin-top:10px;">Email: {{ this.form.email }}</div>
            <div>Student No.: {{ this.form.username }}</div>
          </div>
          <el-form-item prop="code">
            <el-input
              v-model="query.code"
              :clearable="true"
              placeholder="Verification code"
            >
            </el-input>
          </el-form-item>
          <div class="forgetpassword-btn">
            <el-button type="primary" @click="verifyAndContinue">
              Verify code and continue
            </el-button>
          </div>
        </el-form>
      </div>
      <div class="each-step" v-if="this.activeOfSteps === 3">
        <el-form
          ref="step3RulesForm"
          :model="query"
          :rules="step3Rules"
          label-width="0px"
          class="ms-content"
        >
          <el-form-item :inline-message="true" prop="newPassword">
            <el-input
              v-model="query.newPassword"
              :clearable="true"
              :show-password="true"
              placeholder="input your new password"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="newConfirmPassword">
            <el-input
              v-model="query.newConfirmPassword"
              :clearable="true"
              :show-password="true"
              placeholder="Confirm your new password"
            >
            </el-input>
          </el-form-item>
          <div class="forgetpassword-btn">
            <el-button type="primary" @click="resetPassword">
              Reset Password
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { postData } from "../../service/api";

export default {
  data() {
    return {
      controllerUrl: "/forgetPassword",
      activeOfSteps: 1,
      query: {
        emailOrUsername: "",
        actionType: "ForgetPassword",
        code: null,
        newPassword: "",
        newConfirmPassword: "",
      },
      form: {
        email: "",
        username: "",
      },
      passwordRuleForm: {},
      step1Rules: {
        emailOrUsername: [
          {
            required: true,
            message: "Please input your student number or email.",
            trigger: "blur",
          },
        ],
      },
      step2Rules: {
        code: [
          {
            required: true,
            message: "Please input the verification code sent to your email.",
            trigger: "blur",
          },
        ],
      },
      step3Rules: {
        newPassword: [
          {
            required: true,
            message: "Please input your new password.",
            trigger: "blur",
          },
        ],
        newConfirmPassword: [
          {
            required: true,
            message: "Please confirm your new password.",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (this.query.newPassword !== value) {
                callback(
                  new Error(
                    "the confirmed password is not matched with the new password."
                  )
                );
                return;
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    sendVerificationCode() {
      console.log("sendVerificationCode", this.query);
      this.$refs.step1RulesForm.validate((valid) => {
        if (valid) {
          postData(
            `${this.controllerUrl}/sendVerificationCode`,
            this.query
          ).then((res) => {
            if (res.result && res.code === "200") {
              this.form.email = res.result.email;
              this.form.username = res.result.username;
              this.activeOfSteps = 2;
              this.$message.success(
                "Verification code sent to your email successfully"
              );
            } else {
              this.$message.error(`Sent failed, error message: ${res.message}`);
            }
          });
        } else {
          this.$message.error("Please correct the input error!");
          return false;
        }
      });
    },
    verifyAndContinue() {
      this.$refs.step2RulesForm.validate((valid) => {
        if (valid) {
          postData(`${this.controllerUrl}/verifyCode`, this.query).then(
            (res) => {
              if (res.result && res.code === "200") {
                this.passwordRuleForm = res.result;
                this.step3Rules.newPassword.push({
                  pattern: new RegExp(this.passwordRuleForm.pattern),
                  message: this.passwordRuleForm.description,
                });
                this.activeOfSteps = 3;
              } else {
                this.$message.error(
                  `Verification failed, error message: ${res.message}`
                );
              }
            }
          );
        } else {
          this.$message.error("Please correct the input error!");
          return false;
        }
      });
    },
    resetPassword() {
      this.$refs.step3RulesForm.validate((valid) => {
        if (valid) {
          postData(`${this.controllerUrl}/resetPassword`, this.query).then(
            (res) => {
              if (res.result && res.code === "200") {
                this.$message.success("Password reset successfully");
                this.$router.push("/login");
              } else {
                this.$message.error(
                  `Password reset failed, error message: ${res.message}`
                );
              }
            }
          );
        } else {
          this.$message.error("Please correct the input error!");
          return false;
        }
      });
    },
  },
};
</script>
<style>
.forgetpassword-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/student_login_bg.jpg);
  background-size: 100%;
}
.forgetpassword-wrap .ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 1.67rem;
  color: orange;
  border-bottom: 1px solid #ddd;
}
.forgetpassword-wrap .userInfo {
  margin-bottom: 1.67rem;
}
.forgetpassword-wrap .userInfo div {
  font-size: 1.67rem;
  color: #eeeeee;
}
.forgetpassword-wrap .ms-forgetpassword {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 600px;
  margin: -190px 0 0 -275px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.forgetpassword-wrap .ms-forgetpassword .steps {
  padding: 30px 30px 0 30px;
}
.forgetpassword-wrap .ms-content {
  padding: 10px 30px 30px;
}
.forgetpassword-wrap .forgetpassword-btn {
  text-align: center;
}
.forgetpassword-wrap .forgetpassword-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.forgetpassword-wrap .forgetpassword-tips {
  font-size: 1rem;
  line-height: 30px;
  color: #fff;
}
</style>
